import * as React from "react"

import Layout from "../components/layout"
import Layouttraining from "../components/layouttraining"
import Seo from "../components/seo"

const KidsTrainings = () => {
  let title = "Gyermek fitnesz"
  let subTitle = "Hogy fejlődjön a mozgásuk"
  let places = [
    {
      place: "Herceghalom - Csicsergő óvoda",
      date: "H-K-P: 15.30-16.15",
    },
  ]
  let description = (
    <span>
      Gyerekekkel foglalkozni nagy öröm és kihívás is egyben. Öröm, mert jó
      érzés látni a fejlődésüket és hogy mennyire élvezik a mozgást. Kihívás,
      mert a gyerekek hosszabb ideig nehezebben koncentrálnak, nehéz lekötni a
      figyelmüket.{" "}
      <b>
        Érdekes és játékos mozgásfejlesztő gyakorlatokkal azonban tovább lehet
        erősíteni bennük a mozgás szeretetét, fejleszteni lehet a koordinációs
        képességeiket, a koncentrációs képességüket, az erőnlétüket és az
        állóképességüket is, hogy aztán ezek az alapok akár egy egész életre
        megerősítse bennük a rendszeres mozgás szeretetét.
      </b>
    </span>
  )

  let imageSrc = "kids"

  return (
    <Layout>
      <Seo title={title} />
      <Layouttraining
        title={title}
        subTitle={subTitle}
        description={description}
        places={places}
        imageSrc={imageSrc}
      />
    </Layout>
  )
}

export default KidsTrainings
